// src/components/BottomNavBar.js

import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Tooltip from "@mui/material/Tooltip";
import GroupIcon from "@mui/icons-material/Group";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatIcon from "@mui/icons-material/Chat";
import MovieIcon from "@mui/icons-material/Movie";
import StarIcon from "@mui/icons-material/Star";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const BottomNavBar = ({
  value,
  onChange,
  handleMissionClick,
  user,
  topOffset,
  onMenuSelect,
  selectedChat,
}) => {
  // 都道府県のマッピング
  const prefectureMaps = {
    北海道: { shortName: "北海道", logicalName: "hokkaido" },
    青森県: { shortName: "青森", logicalName: "aomori" },
    岩手県: { shortName: "岩手", logicalName: "iwate" },
    宮城県: { shortName: "宮城", logicalName: "miyagi" },
    秋田県: { shortName: "秋田", logicalName: "akita" },
    山形県: { shortName: "山形", logicalName: "yamagata" },
    福島県: { shortName: "福島", logicalName: "fukushima" },
    茨城県: { shortName: "茨城", logicalName: "ibaraki" },
    栃木県: { shortName: "栃木", logicalName: "tochigi" },
    群馬県: { shortName: "群馬", logicalName: "gunma" },
    埼玉県: { shortName: "埼玉", logicalName: "saitama" },
    千葉県: { shortName: "千葉", logicalName: "chiba" },
    東京都: { shortName: "東京", logicalName: "tokyo" },
    神奈川県: { shortName: "神奈川", logicalName: "kanagawa" },
    新潟県: { shortName: "新潟", logicalName: "niigata" },
    富山県: { shortName: "富山", logicalName: "toyama" },
    石川県: { shortName: "石川", logicalName: "ishikawa" },
    福井県: { shortName: "福井", logicalName: "fukui" },
    山梨県: { shortName: "山梨", logicalName: "yamanashi" },
    長野県: { shortName: "長野", logicalName: "nagano" },
    岐阜県: { shortName: "岐阜", logicalName: "gifu" },
    静岡県: { shortName: "静岡", logicalName: "shizuoka" },
    愛知県: { shortName: "愛知", logicalName: "aichi" },
    三重県: { shortName: "三重", logicalName: "mie" },
    滋賀県: { shortName: "滋賀", logicalName: "shiga" },
    京都府: { shortName: "京都", logicalName: "kyoto" },
    大阪府: { shortName: "大阪", logicalName: "osaka" },
    兵庫県: { shortName: "兵庫", logicalName: "hyogo" },
    奈良県: { shortName: "奈良", logicalName: "nara" },
    和歌山県: { shortName: "和歌山", logicalName: "wakayama" },
    鳥取県: { shortName: "鳥取", logicalName: "tottori" },
    島根県: { shortName: "島根", logicalName: "shimane" },
    岡山県: { shortName: "岡山", logicalName: "okayama" },
    広島県: { shortName: "広島", logicalName: "hiroshima" },
    山口県: { shortName: "山口", logicalName: "yamaguchi" },
    徳島県: { shortName: "徳島", logicalName: "tokushima" },
    香川県: { shortName: "香川", logicalName: "kagawa" },
    愛媛県: { shortName: "愛媛", logicalName: "ehime" },
    高知県: { shortName: "高知", logicalName: "kochi" },
    福岡県: { shortName: "福岡", logicalName: "fukuoka" },
    佐賀県: { shortName: "佐賀", logicalName: "saga" },
    長崎県: { shortName: "長崎", logicalName: "nagasaki" },
    熊本県: { shortName: "熊本", logicalName: "kumamoto" },
    大分県: { shortName: "大分", logicalName: "oita" },
    宮崎県: { shortName: "宮崎", logicalName: "miyazaki" },
    鹿児島県: { shortName: "鹿児島", logicalName: "kagoshima" },
    沖縄県: { shortName: "沖縄", logicalName: "okinawa" },
    // 海外の特別な場合
    "海外（アメリカ）": { shortName: "海外（アメリカ）", logicalName: "amerika" },
    "海外（その他）": { shortName: "海外（その他）", logicalName: "foreign" },
  };

  const getPrefectureNameWithoutSuffix = (prefecture) => {
    return prefectureMaps[prefecture]?.shortName || prefecture;
  };

  // 都道府県から地域へのマッピング
  const prefectureToRegionMap = {
    // 東北・北海道地方
    北海道: { region: "東北・北海道" },
    青森県: { region: "東北・北海道" },
    岩手県: { region: "東北・北海道" },
    宮城県: { region: "東北・北海道" },
    秋田県: { region: "東北・北海道" },
    山形県: { region: "東北・北海道" },
    福島県: { region: "東北・北海道" },
    // 関東地方
    茨城県: { region: "関東" },
    栃木県: { region: "関東" },
    群馬県: { region: "関東" },
    埼玉県: { region: "関東" },
    千葉県: { region: "関東" },
    東京都: { region: "関東" },
    神奈川県: { region: "関東" },
    // 中部地方
    新潟県: { region: "中部" },
    富山県: { region: "中部" },
    石川県: { region: "中部" },
    福井県: { region: "中部" },
    山梨県: { region: "中部" },
    長野県: { region: "中部" },
    岐阜県: { region: "中部" },
    静岡県: { region: "中部" },
    愛知県: { region: "中部" },
    // 近畿地方
    三重県: { region: "近畿" },
    滋賀県: { region: "近畿" },
    京都府: { region: "近畿" },
    大阪府: { region: "近畿" },
    兵庫県: { region: "近畿" },
    奈良県: { region: "近畿" },
    和歌山県: { region: "近畿" },
    // 中国地方
    鳥取県: { region: "中国" },
    島根県: { region: "中国" },
    岡山県: { region: "中国" },
    広島県: { region: "中国" },
    山口県: { region: "中国" },
    // 四国地方
    徳島県: { region: "四国" },
    香川県: { region: "四国" },
    愛媛県: { region: "四国" },
    高知県: { region: "四国" },
    // 九州・沖縄地方
    福岡県: { region: "九州・沖縄" },
    佐賀県: { region: "九州・沖縄" },
    長崎県: { region: "九州・沖縄" },
    熊本県: { region: "九州・沖縄" },
    大分県: { region: "九州・沖縄" },
    宮崎県: { region: "九州・沖縄" },
    鹿児島県: { region: "九州・沖縄" },
    沖縄県: { region: "九州・沖縄" },
    // 海外は「海外」で統一
    "海外（アメリカ）": { region: "海外" },
    "海外（その他）": { region: "海外" },
  };

  const getRegionInfo = (prefecture) => {
    return (
      prefectureToRegionMap[prefecture] || { region: "不明" }
    );
  };

  // メニューの状態管理
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (menuItem) => {
    setAnchorEl(null);
    if (menuItem) {
      onMenuSelect(menuItem);
    }
  };

  // ラベルを動的に設定
  const chatLabel =
    selectedChat === "居住県"
      ? getPrefectureNameWithoutSuffix(user?.prefecture)
      : selectedChat;

  return (
    <>
      <BottomNavigation
        showLabels
        value={value}
        onChange={onChange}
        style={{
          position: "fixed",
          top: topOffset,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          maxWidth: "1152px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          height: "56px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Tooltip title="仲間" arrow>
          <BottomNavigationAction
            value={0}
            label="仲間"
            icon={<GroupIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="DDP" arrow>
          <BottomNavigationAction
            value={1}
            label="DDP"
            icon={<FavoriteIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title={chatLabel} arrow>
          <BottomNavigationAction
            value={2}
            label={chatLabel}
            icon={<ChatIcon style={{ fontSize: 30 }} />}
            onClick={handleMenuOpen} // メニューを開く
          />
        </Tooltip>
        <Tooltip title="11/17" arrow>
          <BottomNavigationAction
            value={3}
            label="11/17"
            icon={<MovieIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="ソフィーからのミッション" arrow>
          <BottomNavigationAction
            value={4}
            icon={<StarIcon style={{ fontSize: 30 }} />}
            onClick={handleMissionClick}
          />
        </Tooltip>
      </BottomNavigation>

      {/* メニュー */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null)}
      >
        <MenuItem onClick={() => handleMenuClose("グループ")}>グループ</MenuItem>
        <MenuItem onClick={() => handleMenuClose("居住県")}>
          {getPrefectureNameWithoutSuffix(user?.prefecture)}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("地域")}>
          {getRegionInfo(user?.prefecture).region}
        </MenuItem>
      </Menu>
    </>
  );
};

export default BottomNavBar;

