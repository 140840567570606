import React, { useState, useEffect } from "react";
import {
  IconButton,
  Avatar,
  Tooltip,
  MenuItem,
  Menu,
  Typography,
  Snackbar,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { signOut } from "firebase/auth";
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import GavelIcon from "@mui/icons-material/Gavel";
import PolicyIcon from "@mui/icons-material/Policy";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PeopleIcon from "@mui/icons-material/People";
import VerifiedIcon from "@mui/icons-material/Verified";
import AssignmentIcon from "@mui/icons-material/Assignment"; // これをSurveyIconとして使用します
import SurveyAdmin from "./SurveyAdmin"; // アンケート管理コンポーネントのインポート
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import VideoFileIcon from '@mui/icons-material/VideoFile';

function UserMenu() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userAvatar, setUserAvatar] = useState("/static/images/avatar/2.jpg");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);

  const [user, setUser] = useState(null);
  const [unresolvedCount, setUnresolvedCount] = useState(0);

  useEffect(() => {
    if (auth.currentUser) {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const unsubscribe = onSnapshot(userRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUser(userData);
          setUserAvatar(userData.avatar);
        }
      });

      return () => unsubscribe();
    }
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Logout successful");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("Sign out error", error);
        setOpenSnackbar(true);
      });
  };

  const handleProfileClick = () => {
    handleCloseUserMenu();
    navigate("/profile");
  };

  const handleHomeClick = () => {
    handleCloseUserMenu();
    navigate("/home");
  };

  const handleAdminSettingClick = async (event) => {
    setAdminAnchorEl(event.currentTarget);

    const supportQuery = query(
      collection(db, "support"),
      where("isResolved", "==", false)
    );
    const querySnapshot = await getDocs(supportQuery);
    setUnresolvedCount(querySnapshot.size);
  };

  const handleCloseAdminMenu = () => {
    setAdminAnchorEl(null);
  };

  const handleSupportClick = () => {
    handleCloseUserMenu();
    navigate("/support");
  };

  const handleTermsClick = () => {
    window.open("/termsofservice", "_blank");
    handleCloseUserMenu();
  };

  const handlePrivacyClick = () => {
    window.open("/privacypolicy", "_blank");
    handleCloseUserMenu();
  };

  const handleAdminItemClick = (path) => {
    navigate(path);
    handleCloseAdminMenu();
    handleCloseUserMenu();
  };

  const handleSurveyClick = () => {
    navigate("/admin/surveys");
    handleCloseAdminMenu();
    handleCloseUserMenu();
  };

  const handleVideosClick = () => {
    handleCloseUserMenu();
    navigate("/videos");
  };

  const handleGroupClick = () => {
    if (user?.participatingGroup) {
      navigate(`/group/${user.participatingGroup}`);
    }
    handleCloseAdminMenu();
    handleCloseUserMenu();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="User Avatar" src={userAvatar} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={handleHomeClick}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            ホーム
          </Typography>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
        </MenuItem>

        {user?.participatingGroup && (
          <MenuItem
            onClick={handleGroupClick}
            sx={{
              "&:hover": {
                color: "darkgray",
                "& .MuiListItemIcon-root": {
                  color: "darkgray",
                },
              },
            }}
          >
            <Typography textAlign="left" sx={{ flexGrow: 1 }}>
              グループ
            </Typography>
            <ListItemIcon>
              <Diversity1Icon />
            </ListItemIcon>
          </MenuItem>
        )}

        <MenuItem
          onClick={handleProfileClick}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            プロフィール
          </Typography>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={handleSupportClick}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            お問い合わせ
          </Typography>
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
        </MenuItem>

        {user?.isVerified && (
          <MenuItem
            onClick={handleVideosClick}
            sx={{
              "&:hover": {
                color: "darkgray",
                "& .MuiListItemIcon-root": {
                  color: "darkgray",
                },
              },
            }}
          >
            <Typography textAlign="left" sx={{ flexGrow: 1 }}>
              動画
            </Typography>
            <ListItemIcon>
              <VideoLibraryIcon />
            </ListItemIcon>
          </MenuItem>
        )}

        {user?.isAdmin && (
          <>
            <MenuItem
              onClick={handleAdminSettingClick}
              sx={{
                "&:hover": {
                  color: "darkgray",
                  "& .MuiListItemIcon-root": {
                    color: "darkgray",
                  },
                },
              }}
            >
              <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                管理者設定
              </Typography>
              <ListItemIcon>
                <SettingsSuggestIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              anchorEl={adminAnchorEl}
              open={Boolean(adminAnchorEl)}
              onClose={handleCloseAdminMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => handleAdminItemClick("/admin/music")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  音楽
                </Typography>
                <ListItemIcon>
                  <MusicNoteIcon />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                onClick={() => handleAdminItemClick("/admin/grouping")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  グルーピング
                </Typography>
                <ListItemIcon>
                  <GroupWorkIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() => handleAdminItemClick("/admin/groupList")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  グループ一覧
                </Typography>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() => handleAdminItemClick("/admin/postManagement")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  投稿管理
                </Typography>
                <ListItemIcon>
                  <ChatBubbleOutlineIcon />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                onClick={() => handleAdminItemClick("/admin/videoManagement")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  動画管理
                </Typography>
                <ListItemIcon>
                  <VideoFileIcon />
                </ListItemIcon>
              </MenuItem>



              <MenuItem
                onClick={() => handleAdminItemClick("/admin/support")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  お問い合わせ ({unresolvedCount})
                </Typography>
                <ListItemIcon>
                  <SupportAgentIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() => handleAdminItemClick("/admin/userlist")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  ユーザ一覧
                </Typography>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() => handleAdminItemClick("/admin/tokenRegistration")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  トークン登録
                </Typography>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() =>
                  handleAdminItemClick("/admin/addBankTransferPayment")
                }
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  銀行振込者登録
                </Typography>
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
              </MenuItem>

              <MenuItem
                onClick={() => handleAdminItemClick("/admin/verification")}
                sx={{
                  "&:hover": {
                    color: "darkgray",
                    "& .MuiListItemIcon-root": {
                      color: "darkgray",
                    },
                  },
                }}
              >
                <Typography textAlign="left" sx={{ flexGrow: 1 }}>
                  照合状況
                </Typography>
                <ListItemIcon>
                  <VerifiedIcon />
                </ListItemIcon>
              </MenuItem>
            </Menu>
          </>
        )}

        <MenuItem
          onClick={handleTermsClick}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            利用規約
          </Typography>
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={handlePrivacyClick}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            プライバシーポリシー
          </Typography>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleLogout();
            handleCloseUserMenu();
          }}
          sx={{
            "&:hover": {
              color: "darkgray",
              "& .MuiListItemIcon-root": {
                color: "darkgray",
              },
            },
          }}
        >
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            ログアウト
          </Typography>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="ログアウトしました"
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"機能提供時期のお知らせ"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            グループ機能は8月以降に開放予定です。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserMenu;
