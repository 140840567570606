import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import UserMenu from "./UserMenu";
import NavigationDrawer from "./NavigationDrawer";
import CachedIcon from "@mui/icons-material/Cached";
import VerifiedIcon from "@mui/icons-material/Verified"; // 追加
import { auth, db } from "../api/firebase";
import { onSnapshot, doc } from "firebase/firestore";
import { Link } from "react-router-dom"; // RouterLink としてインポート
import CreateHederMenu from "./CreateHeaderMenu";

function ResponsiveAppBar() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      const userRef = doc(db, "users", uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setUser(doc.data());
        } else {
          console.log("No such document!");
        }
      });

      return () => unsubscribe();
    } else {
      setUser(null);
    }
  }, []);

  const reload = () => {
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" color="inherit">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <NavigationDrawer user={user} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Link to="/home" underline="none">
                MasterMind
              </Link>
            </Typography>
            <CreateHederMenu
              text="再読込"
              icon={CachedIcon}
              onclick={reload}
              complete
            />
            {/* 認証アイコンは isVerified が true でない場合のみ表示 */}
            {!user?.isVerified && (
              <CreateHederMenu
                text="認証"
                icon={VerifiedIcon}
                onclick={() => (window.location.href = "/verifing")} // 認証画面への遷移
                complete
              />
            )}
            <UserMenu />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default ResponsiveAppBar;
