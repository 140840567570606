import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Alert,
  CircularProgress,
} from "@mui/material";
import { db, auth } from "../api/firebase";
import { collection, getDocs, doc, setDoc, serverTimestamp } from "firebase/firestore";

const TokenRegistration = () => {
  const [tokenList, setTokenList] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("¥28,000");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleRegisterTokens = async () => {
    setLoading(true);
    setMessage(null);

    const tokens = tokenList.split("\n").map((token) => token.trim()).filter(Boolean);

    try {
      const existingTokens = new Map();

      // Fetch existing tokens
      const querySnapshot = await getDocs(collection(db, "token2generation"));
      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.token) {
          existingTokens.set(data.token, docSnap.id); // MapにトークンとIDを格納
        }
      });

      let successCount = 0;
      let updatedCount = 0;

      // トークンの登録または更新
      for (const token of tokens) {
        const docId = existingTokens.get(token); // 既存のトークンIDを取得

        if (docId) {
          // トークンが存在する場合は更新
          await setDoc(doc(db, "token2generation", docId), {
            token,
            price: selectedPrice,
            updatedAt: serverTimestamp(),
            updatedBy: auth.currentUser?.uid || "anonymous",
          }, { merge: true }); // マージモードで部分更新
          updatedCount++;
        } else {
          // トークンが存在しない場合は新規登録
          await setDoc(doc(collection(db, "token2generation")), {
            token,
            price: selectedPrice,
            createdAt: serverTimestamp(),
            registeredBy: auth.currentUser?.uid || "anonymous",
          });
          successCount++;
        }
      }

      setMessage({
        successCount,
        updatedCount,
      });

      setTokenList(""); // 入力フィールドをクリア
    } catch (err) {
      console.error("Error registering tokens:", err);
      setMessage({
        error: "トークンの登録中にエラーが発生しました。",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", padding: 3, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h4" gutterBottom>
        トークン登録
      </Typography>

      <FormControl component="fieldset" sx={{ marginBottom: 3 }}>
        <FormLabel component="legend">価格を選択してください</FormLabel>
        <RadioGroup
          value={selectedPrice}
          onChange={(e) => setSelectedPrice(e.target.value)}
          row
        >
          <FormControlLabel value="¥28,000" control={<Radio />} label="¥28,000" />
          <FormControlLabel value="¥39,000" control={<Radio />} label="¥39,000" />
          <FormControlLabel value="¥55,000" control={<Radio />} label="¥55,000" />
        </RadioGroup>
      </FormControl>

      <TextField
        label="トークン一覧"
        multiline
        rows={10}
        fullWidth
        value={tokenList}
        onChange={(e) => setTokenList(e.target.value)}
        placeholder="トークンを改行区切りで貼り付けてください"
        variant="outlined"
        sx={{ marginBottom: 3 }}
      />

      <Button
        onClick={handleRegisterTokens}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? "登録中..." : "登録"}
      </Button>

      {message && message.successCount !== undefined && (
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            {message.successCount}件、新規登録されました。<br />
            {message.updatedCount}件、価格を更新しました。
          </Alert>
        </Snackbar>
      )}
      {message && message.error && (
        <Snackbar
          open={!!message.error}
          autoHideDuration={6000}
          onClose={() => setMessage(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {message.error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TokenRegistration;
