import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box, Container } from "@mui/material";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useNavigate } from "react-router-dom";

const TokenVerificationPage = () => {
  const [userId, setUserId] = useState(null);
  const [isVerified, setIsVerified] = useState(false); // 認証済みかどうか
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // ユーザーIDを取得し、isVerifiedをチェック
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserId(currentUser.uid);
      const userRef = doc(db, "users", currentUser.uid);
      const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setIsVerified(userData.isVerified || false);
        }
      });
      return () => unsubscribe();
    } else {
      setMessage("ログインしてください。");
    }
  }, []);

  useEffect(() => {
    if (isVerified) {
      setRedirecting(true);
      setTimeout(() => {
        navigate("/home");
      }, 10000);
    }
  }, [isVerified, navigate]);

  const handleVerify = async () => {
    if (!token) {
      setMessage("トークンを入力してください。");
      return;
    }

    if (!userId) {
      setMessage("ユーザー情報が見つかりません。ログインしてください。");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      // トークンコレクションを検索
      const tokenQuery = query(
        collection(db, "token2generation"),
        where("token", "==", token)
      );
      const querySnapshot = await getDocs(tokenQuery);

      if (querySnapshot.empty) {
        setMessage("無効なトークンです。");
        setLoading(false);
        return;
      }

      const tokenData = querySnapshot.docs[0]?.data();
      if (!tokenData) {
        setMessage("トークン情報が見つかりません。");
        setLoading(false);
        return;
      }

      // ユーザー情報を更新
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        isVerified: true,
        secondPhasePrice: tokenData.price, // 2期の金額フィールドを更新
        usedToken: token, // 入力されたトークンを保存
      });
      
      setMessage("認証が完了しました。");
      setRedirecting(true);

      // 10秒後にHomeへリダイレクト
      setTimeout(() => {
        navigate("/home");
      }, 10000);
    } catch (error) {
      console.error("Error verifying token:", error.message);
      setMessage("認証中にエラーが発生しました。");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        トークン認証
      </Typography>
      {isVerified ? (
        // 認証済みの場合
        <>
          <Typography
            variant="body1"
            color="success.main"
            align="center"
            sx={{ mt: 2 }}
          >
            認証済です。
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 2 }}
          >
            10秒後にHome画面へ遷移します。
          </Typography>
        </>
      ) : (
        // 未認証の場合
        <>
          {!redirecting && (
            <>
              <Typography variant="body1" align="center" gutterBottom>
                トークンを入力してください。
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  label="トークン"
                  variant="outlined"
                  fullWidth
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleVerify}
                  disabled={loading}
                >
                  {loading ? "認証中..." : "認証する"}
                </Button>
              </Box>
            </>
          )}
          {message && (
            <Typography
              variant="body1"
              color={message.includes("完了") ? "success.main" : "error"}
              align="center"
              sx={{ mt: 2 }}
            >
              {message}
            </Typography>
          )}
          {redirecting && (
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mt: 2 }}
            >
              10秒後にHome画面へ遷移します。
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default TokenVerificationPage;
