import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { db, auth, storage } from "../api/firebase";
import Snackbar from "@mui/material/Snackbar";

import defaultBackImg from "../data/defaultBack.png";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import heic2any from "heic2any";
import CircularProgress from "@mui/material/CircularProgress"; // ローディングスピナーのために追加


const BackImage = ({ noEdit, id }) => {
  const [user, setUser] = useState({});
  const [stickyClass, setStickyClass] = useState("top-16");
  const [backImg, setBackImg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const backImgRef = useRef(null);

  const [conversionLoading, setConversionLoading] = useState(false); // HEIC変換のローディング状態


  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "users", id), (docSnap) => {
      if (docSnap.exists()) {
        setUser(docSnap.data());
      } else {
        console.log("Document does not exist");
      }
    });

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      unsubscribe();
    };
  }, [id]);

  const handleWindowResize = () => {
    setStickyClass(window.innerWidth >= 600 ? "rounded-t-md" : "");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/heic"];
    if (!allowedTypes.includes(file.type)) {
      showSnackbar("許可されていないファイル形式です。");
      return;
    }

    setIsLoading(true);
    let processedFile = file;
    try {
      if (file.type === "image/heic") {
        if (file.type === "image/heic") {
          setConversionLoading(true);
          try {
            console.log("Converting HEIC to JPEG...");
            processedFile = await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.8,
            });
            console.log("Conversion successful");
          } catch (error) {
            console.error("Error converting HEIC to JPEG:", error);
            setSnackbarMessage("HEICファイルの変換に失敗しました。");
            setSnackbarOpen(true);
            setConversionLoading(false);
            return;
          }
          setConversionLoading(false);
        }
      }
      const resizedFile = await resizeImage(processedFile);
      await uploadBackgroundImage(resizedFile);
    } catch (error) {
      console.error("Error uploading image:", error);
      showSnackbar("画像のアップロードに失敗しました。");
    }
    setIsLoading(false);
  };

  // const convertHEICToJPEG = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.onload = () => {
  //         const canvas = document.createElement("canvas");
  //         const ctx = canvas.getContext("2d");
  //         canvas.width = img.width;
  //         canvas.height = img.height;
  //         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //         canvas.toBlob((blob) => {
  //           const newFile = new File([blob], "converted.jpeg", {
  //             type: "image/jpeg",
  //           });
  //           resolve(newFile);
  //         }, "image/jpeg");
  //       };
  //       img.src = e.target.result;
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const resizeImage = async (file) => {
    const maxWidth = 800; // 最大幅
    const maxHeight = 600; // 最大高さ
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          }, file.type);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };


  const uploadBackgroundImage = async (file) => {
    setIsLoading(true); // アップロードの開始時にローディング状態を設定
    const backImgRef = ref(storage, `background/${new Date().getTime()} - ${file.name}`);
    try {
      // 新しい背景画像のアップロード
      const snap = await uploadBytes(backImgRef, file);
      const url = await getDownloadURL(snap.ref);
  
      // Firestoreのユーザードキュメントを更新
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        background: url,
        backgroundPath: snap.ref.fullPath,
      });
  
      // 成功した後で古い画像を削除
      if (user && user.backgroundPath) {
        const oldRef = ref(storage, user.backgroundPath);
        await deleteObject(oldRef).catch((err) => {
          console.error("Error deleting old image: ", err.message);
          // 古い画像の削除に失敗しても、新しい画像のURLを保存
        });
      }
  
      setUser((prevUser) => ({
        ...prevUser,
        background: url,
        backgroundPath: snap.ref.fullPath,
      }));
  
      showSnackbar("背景画像が更新されました。"); // 成功の通知
      setBackImg(null); // アップロード後に画像をリセット
      setIsLoading(false); // ローディング状態の解除
    } catch (err) {
      console.error("Error uploading image: ", err.message);
      setIsLoading(false); // エラーが発生した場合もローディング状態を解除
    }
  };
  


  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  if (isLoading || conversionLoading) {
    // 変換中またはアップロード中のローディングをチェック
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="text-white flex flex-col items-center">
          <CircularProgress color="inherit" />
          <p>
            {conversionLoading ? "画像を変換中..." : "プロフィール更新中..."}
          </p>
        </div>
      </div>
    );
  }


  // if (isLoading) {
  //   return <Loading title="プロフィール" action="更新中" />;
  // }

  return (
    <>
      <div
        className={`${
          noEdit ? "pointer-events-none" : "hover:opacity-50 cursor-pointer"
        } relative flex justify-center items-center w-full h-4/5 group`}
      >
        <img
          src={user.background || defaultBackImg}
          alt="Background"
          className={`${
            noEdit ? "pointer-events-none" : stickyClass
          } w-full h-72 rounded-t-md`}
          onClick={() => backImgRef.current && backImgRef.current.click()}
        />
        {!noEdit && (
          <div className="absolute top-2 sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300">
            <Camera />
            <input
              ref={backImgRef}
              type="file"
              accept=".jpg,.png,.heic"
              onChange={handleFileChange}
              hidden
            />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default BackImage;
