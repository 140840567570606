import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LoginModalContent from "./LoginModalContent";
import { Fade } from "@mui/material";
import { useAuth } from "../context/auth";
import { Navigate } from "react-router-dom";
import { db } from "../api/firebase";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import image from "../data/MMM.png";
import smallImage from "../data/MMM2.png"; // 添付ファイルの画像をインポート

const CustomAppBar = styled(AppBar)({
  backgroundColor: "white",
});

const CustomButton = styled(Button)({
  marginLeft: "auto",
  background: "#ba68c8",
  borderRadius: "20px",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    background: "#ab47bc",
  },
});

const SignUpButton = styled(Button)({
  marginLeft: "8px",
  background: "#d0d0d0",
  borderRadius: "20px",
  color: "black",
  fontWeight: "bold",
  "&:hover": {
    background: "#bcbcbc",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Welcome = () => {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        } else {
          await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL,
            avatarPath: "",
            createdAt: serverTimestamp(),
            isOnline: true,
            isLogin: true,
            isStop: false,
          });

          setUserInfo({
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL,
            avatarPath: "",
            createdAt: serverTimestamp(),
            isOnline: true,
            isLogin: true,
            isStop: false,
          });
        }
      }
    };

    fetchUserInfo();
  }, [user]);

  if (userInfo) {
    console.log("Navigateを開始します");
    return <Navigate to="/home" />;
  }
  
  return (
    <>
      <CustomAppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
            MasterMind
          </Typography>
          <CustomButton color="inherit" onClick={handleOpen}>
            ログイン
          </CustomButton>
          <SignUpButton color="inherit" onClick={handleOpen}>
            無料登録
          </SignUpButton>
        </Toolbar>
      </CustomAppBar>
      <img
        src={windowWidth <= 500 ? smallImage : image}
        alt="MasterMind"
        style={{ marginLeft: "auto" }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <LoginModalContent onClose={handleClose} open={open} />
        </Box>
      </Modal>
    </>
  );
};

export default Welcome;
