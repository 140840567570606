import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // 追加
import { auth, db } from "../api/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useAuth } from "../context/auth";
import { memo } from "react";
import Grid from "@mui/material/Grid";
import ToDoList from "./ToDoList";
import NextMissionList from "./NextMissionList";
import Feed from "./Feed";
import AddPostDialog from "./AddPostDialog";
import RightSections from "./RightSections";
import AudioPlayer from "./AudioPlayer";
import Announcement from "./Announcement";
import PurposeComponent from "./PurposeComponent";
import PurposeSetting from "./PurposeSetting";
import HokkaidoMunicipalitySelect from "./HokkaidoMunicipalitySelect";
import Feeds from "./Feeds";
import UpdateUserTerm from "./UpdateUserTerm";

const MainContent = () => {
  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const getInitUser = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserInfo({ id: docSnap.id, ...docSnap.data() });
      }
    };
    if (user) {
      getInitUser();
    }
  }, [user]);

  // useEffect(() => {
  //   if (userInfo && !userInfo.isVerified) {
  //     navigate("/notverified"); // 追加
  //   }
  // }, [userInfo, navigate]);

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex overflow-hidden">
        <div className="flex-grow  px-1 overflow-y-scroll">
          {/* <UpdateUserTerm /> */}
          <PurposeComponent />
          {/* {user && <PurposeSetting userId={user?.uid} />} */}
          {/* <HokkaidoMunicipalitySelect /> */}
          <div className="my-2"></div>
          <Announcement />
          {userInfo?.isVerified && (
            <>
              <AudioPlayer />
            </>
          )}
          <AddPostDialog />
          {userInfo?.isVerified && (
            <>
              <Feeds />
            </>
          )}

          {/* <Feed /> */}
        </div>

        {/* ToDoリストセクション */}
        <div className="hidden mid:block min-w-80 max-w-80 h-screen overflow-y-scroll">
          <RightSections />
        </div>
      </div>
    </>
  );
};

export default memo(MainContent);
