import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { db, auth } from "../api/firebase";

const prices = [
  { price: "¥28,000", priceId: "price_1QTO2gCVGZwNG78CK836TWT5" },
  { price: "¥39,000", priceId: "price_1QTPD8CVGZwNG78CY174oIbH" },
  { price: "¥55,000", priceId: "price_1QTPFOCVGZwNG78CCeWid1No" },
  { price: "¥50", priceId: "price_1QThcjCVGZwNG78CLaztAHHh" },
];

const PaymentContainer = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No authenticated user found.");
          setLoading(false);
          return;
        }

        const userId = currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          console.error(`User document not found for uid: ${userId}`);
          setLoading(false);
          return;
        }

        const userData = userDoc.data();
        setUserData({ ...userData, userId });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>ユーザー情報を取得できませんでした。</div>;
  }

  const paymentPhase = "2"; // 支払いフェーズを固定
  const title = "マスターマインドメンバーズ２期";
  const description = "マスターマインドの素晴らしい仲間たちと夢を叶える旅のスタート";

  // 定数として価格キーを定義
  const priceKey = "secondPhasePrice";

  // `createdAt` を基準に `priceId` と `price` を決定
  const cutoffDate = new Date("2024-12-01");
  const userCreatedAt = userData.createdAt.toDate();

  let selectedPrice = prices[2]; // デフォルト値

  if (userCreatedAt <= cutoffDate) {
    selectedPrice = prices[0]; // ¥28,000の商品を選択
  } else if (userData[priceKey]) {
    const matchedPrice = prices.find((price) => price.price === userData[priceKey]);
    if (matchedPrice) {
      selectedPrice = matchedPrice; // priceKey に一致する価格を選択
    }
  }

  const hasPayment = userData.payments?.some(
    (payment) => payment.paymentPhase === paymentPhase
  );

  // 振込名義情報を準備
  const formattedPhone = userData.registrationPhone
    ? userData.registrationPhone.slice(-4) // 下4桁を抽出
    : "----";
  const fullName = userData.name || "未登録";

  return (
    <div>
      <StripeCheckoutButton
        userId={userData.userId}
        priceId={selectedPrice.priceId}
        price={selectedPrice.price}
        title={title}
        description={description}
        paymentPhase={paymentPhase}
        hasPayment={hasPayment} // 支払い済みかどうかを渡す
        formattedPhone={formattedPhone} // 電話番号下4桁を渡す
      />
    </div>
  );
};

export default PaymentContainer;
