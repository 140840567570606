import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentCancel = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate("/home"); // ホームに戻る
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f9f9f9"
      px={2}
      textAlign="center"
    >
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        お支払いがキャンセルされました
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        途中でキャンセルされました。再度お試しください。
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRetry}
        sx={{ textTransform: "none", paddingX: 4 }}
      >
        HOMEへ
      </Button>
    </Box>
  );
};

export default PaymentCancel;
