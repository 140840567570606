import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Typography
} from '@mui/material';

const steps = [

    {
      label: 'Season1',
      description: '秋 立秋（りっしゅう） 8/8 ~ 8/22',
      content: '●Chatで皆さんで会話してください。\n●オンライン(zoomなど)で繋がり自己紹介などお互いのことを知ってください。\n●今いるメンバーでオンラインで繋がる日にちを決めてください。\n●むしろDDPについては語らないでください＾＾\n'
    },
    {
      label: 'Season2',
      description: '秋 処暑（しょしょ） 8/23 ~ 9/7',
      content: '●メンバーの事を知って理解しあう期間\n例）・今いるメンバーの共通点を１つ見つけてください。\n例）・みつろうさんの好きなお話はなに？'
    },
  
  {
    label: 'Season3',
    description: '秋 白露（はくろ） 9/8 ~ 9/21',
    content: '●今いるメンバーで実際に会う日程を決めましょう。\n●今参加しているメンバーで共通して取り組むことを決め、取組状況や感想などをLINEやチャットにて報告・コメント・アドバイスし合いましょう。（何個でも可）\n（例）はサイトのトップページに記載してあります。ご参考になさってください。'

  },
  {
    label: 'Season4',
    description: '秋 秋分（しゅうぶん） 9/22 ~ 10/7',
    content: '●7/30のDDPについての動画を見直してみましょう。\n●DDPの作成のポイントについてグループ内でディスカッションし、まとめてみましょう。\n●ポイントをもとに自分のDDPを見直してみましょう。'
  },



  {
    label: 'Season5',
    description: '秋 寒露（かんろ） 10/8 ~ 10/23',
    content: '●グループメンバーのDDPについてみんなで深堀りし、各々のDDPを確定しましょう。\n●サイトのDDPを更新しましょう。'
  },
  {
    label: 'Season6',
    description: '秋 霜降（そうこう） 10/24 ~ 11/6',
    content: 'Season5と同様\n●グループメンバーのDDPについてみんなで深堀りし、各々のDDPを確定しましょう。\n●サイトのDDPを更新しましょう。\nnew !!!\n●30分間グループで共通で行うことを決め、毎日チャット等で取り組み状況を報告、応援・励ましのコメントをし合ってください。'
  },
  {
    label: 'Season7',
    description: '冬 立冬（りっとう） 11/7 ~ 11/21',
    content: '●「〇〇さんのDDPが叶う30の理由」の本をメンバーの人数分を作りましょう。\n●30分間、グループで共通で行うことを決め、毎日チャット等で取り組み状況を報告、応援のコメントをし合いましょう。'
  },
  {
    label: 'Season8',
    description: '冬 小雪（しょうせつ） 11/22 ~ 12/6',
    content: '●ビジョンボードを作成しましょう。\n●予祝ワークを行いましょう。\n●DDPを叶えるための行動をしましょう。'
  },
  {
    label: 'Season9',
    description: '冬 大雪（たいせつ） 12/7 ~ 12/20',
    content: '●DDPを叶えるための行動をしましょう'
  },
//   {
//     label: 'Season10',
//     description: '冬 冬至（とうじ） 12/21 ~ 1/3',
//     content: 'Season10の内容をここに入力します。'
//   },
];

const Schedule = () => {
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    return (
      <div style={{ width: '100%', marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '600px' }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel onClick={handleStep(index)}>
                  {step.label} - {step.description}
                </StepLabel>
                <StepContent>
                  {step.content.split('\n').map((line, idx) => (
                    <Typography key={idx}>{line}</Typography>
                  ))}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {/* {activeStep === steps.length && (
            <Paper square elevation={0} style={{ padding: '16px' }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
            </Paper>
          )} */}
        </div>
      </div>
    );
  };

export default Schedule;
