import React, { useState, useEffect } from "react";
import { db } from "../api/firebase";
import { collection, query, getDocs, updateDoc, doc, orderBy } from "firebase/firestore";
import { Typography, Checkbox, FormControlLabel, Divider } from "@mui/material";

const VideoManagement = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videosCollection = collection(db, "videos");
        const videosQuery = query(videosCollection, orderBy("createdAt", "desc"));
        const snapshot = await getDocs(videosQuery);
        const videosData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setVideos(videosData);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleCheckboxChange = async (videoId, term) => {
    try {
      const videoIndex = videos.findIndex((video) => video.id === videoId);
      const updatedAllowedTerm = videos[videoIndex].allowedTerm?.includes(term)
        ? videos[videoIndex].allowedTerm.filter((t) => t !== term)
        : [...(videos[videoIndex].allowedTerm || []), term];

      // Update Firestore
      const videoRef = doc(db, "videos", videoId);
      await updateDoc(videoRef, { allowedTerm: updatedAllowedTerm });

      // Update local state
      const updatedVideos = [...videos];
      updatedVideos[videoIndex].allowedTerm = updatedAllowedTerm;
      setVideos(updatedVideos);
    } catch (error) {
      console.error("Error updating allowedTerm:", error);
    }
  };

  const truncateDescription = (description) => {
    const maxLength = 200; // Adjust based on estimated character count for two lines
    return description && description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        動画管理画面
      </Typography>
      <Divider style={{ marginBottom: "20px" }} />
      {videos.map((video) => (
        <div
          key={video.id}
          style={{
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "16px",
          }}
        >
          <Typography variant="h6">{video.title || "Untitled Video"}</Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: "10px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {truncateDescription(video.description || "No description available")}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px" }}>
            作成日: {video.createdAt ? video.createdAt.toDate().toLocaleString() : "Unknown"}
          </Typography>
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            {[1, 2, 3].map((term) => (
              <FormControlLabel
                key={term}
                control={
                  <Checkbox
                    checked={video.allowedTerm?.includes(term) || false}
                    onChange={() => handleCheckboxChange(video.id, term)}
                  />
                }
                label={`${term}期`}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoManagement;
