import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

const SurveyDialog = ({ open, onClose, surveyId, groupId }) => {
  const [surveyAnswers, setSurveyAnswers] = useState({
    activityNumber: "",
    missions: [],
    ddpAchieved: "",
    participatePrefectureActivities: "",
    mastermind2ndParticipation: "",
    mastermind2ndContent: [],
    mastermind2ndContentOther: "",
    mastermind2ndReason: "",
  });

  const [
    isMastermind2ndContentOtherChecked,
    setIsMastermind2ndContentOtherChecked,
  ] = useState(false);

  const [
    isMastermind2ndParticipationYes,
    setIsMastermind2ndParticipationYes,
  ] = useState(false);

  const [isThankYouDialogOpen, setThankYouDialogOpen] = useState(false);

  const handleSurveyAnswerChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "missions" || name === "mastermind2ndContent") {
      const updatedArray = checked
        ? [...surveyAnswers[name], value]
        : surveyAnswers[name].filter((item) => item !== value);

      setSurveyAnswers((prev) => ({ ...prev, [name]: updatedArray }));

      if (name === "mastermind2ndContent" && value === "other") {
        setIsMastermind2ndContentOtherChecked(checked);
        if (!checked) {
          setSurveyAnswers((prev) => ({
            ...prev,
            mastermind2ndContentOther: "",
          }));
        }
      }
    } else if (name === "mastermind2ndParticipation") {
      setSurveyAnswers((prev) => ({ ...prev, [name]: value }));
      setIsMastermind2ndParticipationYes(value === "はい");

      if (value === "いいえ") {
        setSurveyAnswers((prev) => ({
          ...prev,
          mastermind2ndContent: [],
          mastermind2ndContentOther: "",
          mastermind2ndReason: "",
        }));
        setIsMastermind2ndContentOtherChecked(false);
      }
    } else {
      setSurveyAnswers((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSaveSurvey = async () => {
    const user = auth.currentUser;

    if (!user) {
      alert("ユーザーが認証されていません。");
      return;
    }

    if (!surveyAnswers || !groupId) return;

    if (
      !surveyAnswers.activityNumber ||
      !surveyAnswers.ddpAchieved ||
      !surveyAnswers.participatePrefectureActivities ||
      !surveyAnswers.mastermind2ndParticipation
    ) {
      alert("すべての必須項目に回答してください。");
      return;
    }

    if (
      surveyAnswers.mastermind2ndParticipation === "はい" &&
      !surveyAnswers.mastermind2ndReason
    ) {
      alert("マスターマインドメンバーズ第2期を希望する理由を記載してください。");
      return;
    }

    const surveyData = {
      createdAt: serverTimestamp(),
      surveyId,
      answers: { ...surveyAnswers },
      userId: user.uid,
      groupId,
    };

    try {
      const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
      await setDoc(userFabRef, surveyData);

      const fabRef = collection(db, "fab");
      await addDoc(fabRef, surveyData);

      console.log(
        "アンケートが`/users/userId/fab`と`/fab`コレクションに保存されました"
      );

      setThankYouDialogOpen(true);
    } catch (error) {
      console.error("アンケートの保存に失敗しました", error);
      alert("アンケートの保存中にエラーが発生しました。");
    }
  };

  const handleThankYouDialogClose = () => {
    setThankYouDialogOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog open={open && !isThankYouDialogOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>

          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="activity-number-label">
                グループでの実際の活動人数は？
              </InputLabel>
              <Select
                labelId="activity-number-label"
                name="activityNumber"
                value={surveyAnswers.activityNumber}
                onChange={handleSurveyAnswerChange}
                label="グループでの実際の活動人数は？"
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i + 1} value={10 - i}>
                    {10 - i}人
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                グループで取り組めたミッションを教えてください。
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("onlineMeeting")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="onlineMeeting"
                    />
                  }
                  label="オンライン（Zoomなど）で顔合わせをした。"
                />
                {/* 他のミッションも追加 */}
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleSaveSurvey} color="primary" variant="contained">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* ありがとうございますダイアログ */}
      <Dialog
        open={isThankYouDialogOpen}
        onClose={handleThankYouDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ありがとうございます</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            アンケートにご協力いただき、ありがとうございます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SurveyDialog;
