// src/components/Group.js

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import SurveyIcon from "@mui/icons-material/QuestionAnswer";
import Drawer from "@mui/material/Drawer";
import BottomNavBar from "./BottomNavBar";
import GroupMembers from "./GroupMembers";
import DDPSetting from "./DDPSetting";
import GroupChatEx from "./GroupChatEx";
import PrefectureChat from "./PrefectureChat";
import Webinar from "./Webinar";
import SophieMission from "./SophieMission";
import SurveyDialog from "./SurveyDialog";

const Group = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [currentView, setCurrentView] = useState("仲間");
  const [user, setUser] = useState(null);
  const [isMissionDrawerOpen, setMissionDrawerOpen] = useState(false);
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [isFabVisible, setFabVisible] = useState(true);

  // 選択されたチャットタイプを管理するステートを追加
  const [selectedChat, setSelectedChat] = useState("Chat");

  const surveyId = "202411Early";
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const topOffset = isSmallScreen ? "56px" : "64px";

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);

          const userSurveyQuery = query(
            collection(db, "users", userId, "fab"),
            where("surveyId", "==", surveyId)
          );
          const userSurveySnap = await getDocs(userSurveyQuery);
          setFabVisible(userSurveySnap.empty);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    checkSurveyResponse();
  }, [surveyId]);

  useEffect(() => {
    const fetchGroup = async () => {
      if (!user) return;

      setLoading(true);
      try {
        if (!user.isAdmin && user.participatingGroup !== id) {
          navigate(`/group/${user.participatingGroup}`);
          return;
        }

        const docRef = doc(db, "groups", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setGroup(docSnap.data());
        } else {
          setError("Group not found");
        }
      } catch (err) {
        setError("Failed to fetch group");
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  // 都道府県から地域へのマッピング
  const prefectureToRegionMap = {
    北海道: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    青森県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    岩手県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    宮城県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    秋田県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    山形県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    福島県: { region: "東北・北海道", groupId: "tohoku-hokkaido" },
    茨城県: { region: "関東", groupId: "kanto" },
    栃木県: { region: "関東", groupId: "kanto" },
    群馬県: { region: "関東", groupId: "kanto" },
    埼玉県: { region: "関東", groupId: "kanto" },
    千葉県: { region: "関東", groupId: "kanto" },
    東京都: { region: "関東", groupId: "kanto" },
    神奈川県: { region: "関東", groupId: "kanto" },
    新潟県: { region: "中部", groupId: "chubu" },
    富山県: { region: "中部", groupId: "chubu" },
    石川県: { region: "中部", groupId: "chubu" },
    福井県: { region: "中部", groupId: "chubu" },
    山梨県: { region: "中部", groupId: "chubu" },
    長野県: { region: "中部", groupId: "chubu" },
    岐阜県: { region: "中部", groupId: "chubu" },
    静岡県: { region: "中部", groupId: "chubu" },
    愛知県: { region: "中部", groupId: "chubu" },
    三重県: { region: "近畿", groupId: "kinki" },
    滋賀県: { region: "近畿", groupId: "kinki" },
    京都府: { region: "近畿", groupId: "kinki" },
    大阪府: { region: "近畿", groupId: "kinki" },
    兵庫県: { region: "近畿", groupId: "kinki" },
    奈良県: { region: "近畿", groupId: "kinki" },
    和歌山県: { region: "近畿", groupId: "kinki" },
    鳥取県: { region: "中国", groupId: "chugoku" },
    島根県: { region: "中国", groupId: "chugoku" },
    岡山県: { region: "中国", groupId: "chugoku" },
    広島県: { region: "中国", groupId: "chugoku" },
    山口県: { region: "中国", groupId: "chugoku" },
    徳島県: { region: "四国", groupId: "shikoku" },
    香川県: { region: "四国", groupId: "shikoku" },
    愛媛県: { region: "四国", groupId: "shikoku" },
    高知県: { region: "四国", groupId: "shikoku" },
    福岡県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    佐賀県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    長崎県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    熊本県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    大分県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    宮崎県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    鹿児島県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    沖縄県: { region: "九州・沖縄", groupId: "kyushu-okinawa" },
    // 特別な場合があればここに追加
    "海外（アメリカ）": { region: "海外", groupId: "overseas" },
    "海外（その他）": { region: "海外", groupId: "overseas" },
  };

  const getRegionInfo = (prefecture) => {
    return prefectureToRegionMap[prefecture] || { region: "不明", groupId: "unknown" };
  };

  const getPrefectureLogicalName = (prefecture) => {
    const prefectureMaps = {
      北海道: "hokkaido",
      青森県: "aomori",
      岩手県: "iwate",
      宮城県: "miyagi",
      秋田県: "akita",
      山形県: "yamagata",
      福島県: "fukushima",
      茨城県: "ibaraki",
      栃木県: "tochigi",
      群馬県: "gunma",
      埼玉県: "saitama",
      千葉県: "chiba",
      東京都: "tokyo",
      神奈川県: "kanagawa",
      新潟県: "niigata",
      富山県: "toyama",
      石川県: "ishikawa",
      福井県: "fukui",
      山梨県: "yamanashi",
      長野県: "nagano",
      岐阜県: "gifu",
      静岡県: "shizuoka",
      愛知県: "aichi",
      三重県: "mie",
      滋賀県: "shiga",
      京都府: "kyoto",
      大阪府: "osaka",
      兵庫県: "hyogo",
      奈良県: "nara",
      和歌山県: "wakayama",
      鳥取県: "tottori",
      島根県: "shimane",
      岡山県: "okayama",
      広島県: "hiroshima",
      山口県: "yamaguchi",
      徳島県: "tokushima",
      香川県: "kagawa",
      愛媛県: "ehime",
      高知県: "kochi",
      福岡県: "fukuoka",
      佐賀県: "saga",
      長崎県: "nagasaki",
      熊本県: "kumamoto",
      大分県: "oita",
      宮崎県: "miyazaki",
      鹿児島県: "kagoshima",
      沖縄県: "okinawa",
      // 特別な場合があればここに追加
      "海外（アメリカ）": "amerika",
      "海外（その他）": "foreign",
    };
    return prefectureMaps[prefecture] || prefecture;
  };

  const handleMissionClick = () => {
    setMissionDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setMissionDrawerOpen(false);
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);

    // ナビゲーションの選択に応じてビューを変更
    if (newValue === 0) {
      setCurrentView("仲間");
      setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 1) {
      setCurrentView("DDP");
      setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 2) {
      // Chat ボタンが押されたときはメニューを表示するので、currentView は変更しない
    } else if (newValue === 3) {
      setCurrentView("11/17");
      setSelectedChat("Chat"); // ラベルをリセット
    } else if (newValue === 4) {
      // ソフィーのミッションボタンは別途処理
    }
  };

  const handleMenuSelect = (menuItem) => {
    // メニュー選択時にビューを変更
    if (menuItem === "グループ") {
      setCurrentView("グループチャット");
      setValue(2); // ナビゲーションの選択状態を更新
      setSelectedChat("グループ");
    } else if (menuItem === "居住県") {
      setCurrentView("居住県チャット");
      setValue(2);
      setSelectedChat(user?.prefecture || "居住県");
    } else if (menuItem === "地域") {
      setCurrentView("地域チャット");
      setValue(2);
      const regionInfo = getRegionInfo(user?.prefecture);
      setSelectedChat(regionInfo.region);
    }
  };

  const handleSurveyClick = async () => {
    if (!user) return;

    try {
      const userSurveyQuery = query(
        collection(db, "users", user.uid, "fab"),
        where("surveyId", "==", surveyId)
      );
      const userSurveySnap = await getDocs(userSurveyQuery);

      if (userSurveySnap.empty) {
        setSurveyDialogOpen(true);
      } else {
        setFabVisible(false);
        console.log("ユーザーはすでにアンケートに回答済みです");
      }
    } catch (error) {
      console.error("アンケート回答のチェックに失敗しました", error);
    }
  };

  const handleSurveyClose = () => {
    setSurveyDialogOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}>
      <BottomNavBar
        value={value}
        onChange={handleNavigationChange}
        handleMissionClick={handleMissionClick}
        user={user}
        topOffset={topOffset}
        onMenuSelect={handleMenuSelect}
        selectedChat={selectedChat} // 追加
      />
      <Fab
        id="survey-fab"
        color="primary"
        aria-label="アンケート"
        onClick={handleSurveyClick}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "16px",
          zIndex: 1100,
          display: isFabVisible ? "block" : "none",
        }}
      >
        <SurveyIcon />
      </Fab>

      <div style={{ flex: 1, marginTop: topOffset }}>
        {currentView === "仲間" && <GroupMembers group={group} />}
        {currentView === "DDP" && <DDPSetting />}
        {currentView === "グループチャット" && <GroupChatEx groupId={id} />}
        {currentView === "居住県チャット" && (
          <PrefectureChat groupId={getPrefectureLogicalName(user?.prefecture)} />
        )}
        {currentView === "地域チャット" && (
          <PrefectureChat groupId={getRegionInfo(user?.prefecture).groupId} />
        )}
        {currentView === "11/17" && <Webinar />}
      </div>

      <Drawer anchor="right" open={isMissionDrawerOpen} onClose={handleDrawerClose}>
        <SophieMission onClose={handleDrawerClose} />
      </Drawer>

      <SurveyDialog open={isSurveyDialogOpen} onClose={handleSurveyClose} />
    </div>
  );
};

export default Group;
