import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home"); // ホームページに戻る
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f9f9f9"
      px={2}
      textAlign="center"
    >
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        お支払いが完了しました！
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        この度は「マスターマインドメンバーズ」にご参加いただき、誠にありがとうございます。
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
        不明点がある場合は、お問い合わせページでお問い合わせください。
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{ textTransform: "none", paddingX: 4 }}
      >
        ホームに戻る
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
